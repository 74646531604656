
@import './mixins'; 


.cta_link {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .cta_link {
    margin-left: 2em;
  }
  span {
    display: inline-block;
    font-size: inherit;
    line-height: 1;
    position: relative;
    &:before {
      background-color: transparent;
      bottom: -2px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 100%;
    }
  }
  @include screendm(desktop) {
    &:hover {
      span:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    span:before {
      background-color: currentColor;
    }
  }
}

.nd_home {
  min-height: 60vh;
  .nd_journ:last-child {
    border-bottom: 0
  }
  .nd_head-nodesk.nd_head-nodesk {
    @include screendm(desktop) {
      display: none;
    }
  }

  .nd_head-nomob.nd_head-nomob {
    @include screen(mobile) {
      display: none;
    }
  }
}

.nd_title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

.nd_untitle {
  @include smoothfont;
  font-family: $font-acumin;
  font-weight: normal;
  letter-spacing: unset;
  text-transform: none;
}


.nd_head-nodesk {
  @include screendm(desktop) {
    display: none;
  }
}

.nd_home-caption {
  box-sizing: border-box;
  p {
    @include smoothfont;
    line-height: 1.6;
    font-size: 0.95em;
  }
  .nd_home-title,
  .nd_home-el_link {
    + .nd_copy-body {
      margin-top: 1em;
    }
  }
  @include screendm(desktop) {
    column-gap: 3em;
    display: flex;
    padding-top: 2.25em;
  }
  @include screen(mobile) {
    padding-top: 1.5em;
  }
}

.nd_home-capleft,
.nd_home-capright {
  flex: 1;
}

.nd_home-caption-aright {
  align-items: flex-end;
}

.nd_home-capright {
  @include screendm(desktop) {
    text-align: right;
  }
  @include screen(mobile) {
    margin-top: 1.5em;
  }
}

.nd_home-title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 2.2rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1;
  text-transform: uppercase;
  @include screen(mobile) {
    font-size: 7vw;
  }
}

.nd_home-subtitle {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 1.15rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  line-height: 1.3;
  text-transform: uppercase;
}

.nd_cta-link {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.15em;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .nd_cta-link {
    margin-left: 2em;
  }
  span {
    display: inline-block;
    font-size: inherit;
    line-height: 1;
    position: relative;
    &:before {
      background-color: transparent;
      bottom: -2px;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      transition: all 0.2s ease-in-out;
      width: 100%;
    }
  }
  @include screendm(desktop) {
    &:hover {
      span:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    span:before {
      background-color: currentColor;
    }
  }
}

.nd_home-el_link {
  text-decoration: none;
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:active {
    text-decoration: underline;
  }
}

.nd_hero {
  @include nd_cont;
  > .nd_full-content {
    height: unset;
    position: absolute;
    
    width: unset;
    @include screendm(desktop) {
      top: 50%;
      transform: translate(0, -50%);
      left: 3em;
    }
    @include screen(mobile) {
      bottom: 5vw;
      left: 5vw;
    }
  }
}

.nd_twoeq + .nd_hero.nd_hero-notmain,
.nd_hero-notmain {
  @include screendm(desktop) {
    margin-top: 7em;
  }
  @include screen(mobile) {
    margin-top: 3.75em;
  }
}

.nd_hero-image {
  background-color: #ccc;
  overflow: hidden;
}

.nd_hero-split {
  min-height: 65vh;
  @include screen(mobile) {
    .nd_home-image {
      min-height: 80vh;
      overflow: hidden;
    }
    .nd_home-img {
      height: 80vh;
      width: auto;
    }
  }
}

.nd_overlay-split {
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .nd_home-el_link {
    align-items: center;
    color: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
  }
  @include screen(mobile) {
    flex-direction: column;
  }
}

.nd_home-img {
  display: block;
  height: auto;
  object-fit: cover;
  width: 100%
}

.nd_home-imgd {
  display: none;
  @include screen(desktop) {
    display: block;
  }
}
.nd_home-imgm {
  display: none;
  @include screen(mobile) {
    display: block;
  }
}

.nd_home-image {
  background-color: #ccc;
  flex: 1;
  position: relative;
  @include screen(nd_med) {
    max-height: 75vh;
    overflow: hidden;
  }
  a {
    img {
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
    @include screendm(desktop) {
      &:hover {
        img {
          opacity: 0.8
        }
      }
    }
    &:active {
      img {
        opacity: 0.8
      }
    }
  }
}

.nd_twoeq {
  @include nd_cont;
  column-gap: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include screendm(desktop) {
    margin-top: 7em;
  }
  @include screen(mobile) {
    grid-template-columns: repeat(1,1fr);
    margin-top: 3.75em;
  }
}

.nd_twoeq-item + .nd_twoeq-item {
  @include screen(mobile) {
    margin-top: 2em;
  }
}

.nd_twoeq-item .nd_home-caption:not(.captflex) {
  @include screendm(desktop) {
    flex-direction: column;
  }
}

.nd_twoeq-item .nd_home-caption.captflex {
  @include screendm(desktop) {
    display: flex;
    a, div {
      + .nd_copy-cta {
        margin-top: 0;
      }
    }
    .nd_home-subtitle {
      flex: 1;
    }
  }
}

.nd_copy-cta {
  @include screen(mobile) {
    text-align: left;
  }
}

.nd_text {
  @include nd_cont;
  column-gap: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  &.nd_home-caption {
    padding-top: 0;
  }
  @include screendm(desktop) {
    margin-top: 7em;
  }
  @include screen(mobile) {
    display: block;
    margin-top: 3.75em;
    .nd_home-title {
      margin-bottom: 2rem;
    }
  }
}

.nd_text-copy {
  padding-right: 20%;
  text-align: left;
  .nd_copy-body {
    @include smoothfont;
    font-size: 1.1em;
    line-height: 1.6;
  }
}

.nd_twooff {
  @include nd_cont;
  column-gap: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .nd_home-caption {
    display: block;
    &.nd_home-caption-aright {
      text-align: right;
    }
    .nd_home-subtitle + .nd_copy-body {
      margin-top: 1.5rem;
    }
  }
  @include screendm(desktop) {
    margin-top: 7em;
  }
  @include screen(mobile) {
    display: block;
    margin-top: 3.75em;
  }
}

a, div, h1 {
  + .nd_copy-cta {
    margin-top: 2rem;
  }
}

.nd_twooff-smaller {
  @include screendm(desktop) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .nd_home-image {
      flex: 0 1 auto;
    }
    .nd_home-image,
    .nd_home-caption {
      width: 70%;
    }
  }
}

.nd_fullwide {
  .nd_fullbleed-hero {
    align-items: center;
    display: flex;
    min-height: 88vh;
    overflow: hidden;
    position: relative;
    .nd_home-image {
      background-color: #eee;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .nd_home-img {
      height: 100%;
    }
  }
  .nd_fullbleed-captions {
    @include nd_cont;
  }
  @include screendm(desktop) {
    &.nd_hero-notmain {
      margin-top: 7em;
    }
  }
  @include screen(mobile) {
    min-height: 45vh;
    &.nd_hero-notmain {
      margin-top: 3.75em;
    }
    .nd_home-image,
    .nd_home-img {
      height: 100%;
      max-height: unset;
    }
    .nd_full-content {
      height: unset;
      width: 100%;
    }
  }
}

// if enabled, add position fixed to header and turn off bg when down
.nd_fullbleed {
  align-items: center;
  display: flex;
  height: 100vh;
  margin-bottom: 6em;
  overflow: hidden;
  position: relative;
  .nd_home-image {
    background-color: #eee;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .nd_home-img {
    height: 100%;
    mix-blend-mode: multiply;
  }
  .nd_full-content {
    font-size: 1.3em;
    height: unset;
  }
  @include screen(mobile) {
    // remove
    margin-bottom: 3.75em;

    .nd_home-image,
    .nd_home-img {
      height: 100%;
    }
    .nd_full-content {
      height: unset;
      width: 100%;
    }
  }
}

.nd_full-content {
  // needs to have a color toggle
  @include nd_cont;
  box-sizing: border-box;
  color: #fff;
  height: 100%;
  z-index: 2;
  a + .nd_copy-cta, 
  div + .nd_copy-cta, 
  h1 + .nd_copy-cta {
    margin-top: 3rem;
  }
  a + .nd_copy-body, 
  div + .nd_copy-body, 
  h1 + .nd_copy-body {
    margin-top: 2rem;
  }
  @include screen(mobile) {
    height: 38vh;
  }
}

.nd_journ {
  border: 1px solid #dddad0;
  border-width: 1px 0;
  padding: 6em 0;
  @include screendm(desktop) {
    margin-top: 7em;
  }
  @include screen(mobile) {
    margin-top: 3.75em;
    padding: 3.75em 0;
  }
  + .nd_journ {
    border-top: 0;
    margin-top: 0;
  }
}

.jour_right {
  @include screendm(desktop) {
    grid-auto-flow: dense;
    .nd_twooff-smaller {
      grid-column: 2;
    }
    .nd_journ-copy {
      justify-self: flex-end;
    }
  }
}

.nd_journ-content {
  @include nd_cont;
  column-gap: 3em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .nd_home-image {
    border: 3px solid #000;
  }
  .nd_copy-cta {
    margin-top: 3rem;
  }
  .nd_home-title {
    line-height: 1.3;
    margin-bottom: 3rem;
  }
  @include screendm(desktop) {
    .nd_home-image {
      width: 70%;
    }
  }
  @include screen(mobile) {
    display: block;
    .nd_home-image {
      margin-right: 3em;
    }
  }
}

.nd_journ-copy {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  line-height: 1.6;
  @include screendm(desktop) {
    max-width: 70%;
    transform: translate(0, -20%);
  }
  @include screen(mobile) {
    display: block;
    margin-top: 3rem;
  }
}

.nd_journ-tag {
  @include smoothfont;
  font-family: $font-mono;
  font-size: 1em;
  letter-spacing: 0.01em;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  transform: translate(60%, 0) rotate(-90deg);
  transform-origin: top right;
  @include screen(mobile) {
    transform: translate(1.5em) rotate(-90deg);
  }
}


.newd {
  [recs-btn],
  .cart_swatch-wr {
    display: none;
  }
}


// HOME HERO -------------------------------------------------------------


.hero__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  display: block;
  height: auto;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}


.home_fact {
  border: 1px solid #dddad0;
  border-width: 1px 0;
  margin-bottom: 7em;

  .cont {
    @include nd_cont;
    border: 1px solid #dddad0;
    border-width: 0 1px;
    position: relative;
    @include screen(mobile) {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
  .fact_link {
    display: block;
    text-decoration: none;
    @include screendm(desktop) {
      &:hover {
        .cta span:before {
          background-color: currentColor;
        }
      }
    }
    &:active {
      .cta span:before {
        background-color: currentColor;
      }
    }
  }
  .hero {
    align-items: center;
    background-color: #fff;
    color: #252525;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    min-height: 85vh;
  }
  .title, p {
    @include nd_title;
    line-height: 1;
  }
  .title {
    @include screendm(desktop) {
      margin: 2.5vw 0 2.6vw;
      font-size: 5vw;
      line-height: 0.8;
    }
    @include screen(mobile) {
      font-size: 11vw;
      margin: 6.5vw 0 7.6vw;
    }
  }
  p {
    font-size: 1.5vw;
    @include screen(mobile) {
      font-size: 5vw;
    }
  }
  .cta {
    @extend .nd_cta-link;
    transform: translate(0, 13vh);
  }
}


[block-g] {
  display: none;
}

[nh="both"] ~ #MainContent [index-wr] {
  [block-g="both"] {
    display: block;
  }
  .nd_twoeq,
  .nd_twooff,
  .nd_text {
    &[block-g="both"] {
      display: grid;
      @include screen(mobile) {
        display: block;
      }
    }
  }
  .nd_fullbleed {
    &[block-g="both"] {
      display: flex;
    }
  }
}

[nh="men"] ~ #MainContent [index-wr] {
  [block-g="men"] {
    display: block;
  }
  .nd_twoeq,
  .nd_twooff,
  .nd_text {
    &[block-g="men"] {
      display: grid;
      @include screen(mobile) {
        display: block;
      }
    }
  }
  .nd_fullbleed {
    &[block-g="men"] {
      display: flex;
    }
  }
}

[nh="women"] ~ #MainContent [index-wr] {
  [block-g="women"] {
    display: block;
  }
  .nd_twoeq,
  .nd_twooff,
  .nd_text {
    &[block-g="women"] {
      display: grid;
      @include screen(mobile) {
        display: block;
      }
    }
  }
  .nd_fullbleed {
    &[block-g="women"] {
      display: flex;
    }
  }
}







