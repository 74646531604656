@import './mixins'; 

.nd_head {
  background-color: #fff;
  border: 1px solid transparent;
  border-width: 0 0 1px;
  box-sizing: border-box;
  color: #333;
  font-size: 1.1rem;
  padding: 2em 0;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 400;
  &.on {
    transition: all 0.25s ease-in-out;
  }
  @include screen(mobile) {
    padding: 1em;
    .head-logo_svg {
      height: 1.5em;
    }
  }
  .icon-hamberger {
    path {
      stroke: currentColor;
    }
  }
}

.head-logo_svg {
  height: 2em;
  padding: 0;
  width: auto;
}

.svg-logo {
  height: 100%;
  width: auto;
  display: block;
  fill: currentColor;
  transition: all 0.1s linear;
}

#shopify-section-header_new.nd_fullbleed_header {
  background-color: transparent;
  color: #fff;
  position: fixed;
  transition: all 0.2s ease-in-out;
  &.on {
    background-color: #fff;
    color: #000
  }
}

.nd_head-content {
  @include nd_cont;
  position: relative;
  @include screen(mobile) {
    padding: 0;
  }
}

.nd_head-top {
  align-items: flex-end;
  display: flex;
  button, a:not(.head-logo_link) {
    @include smoothfont;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: inherit;
    line-height: 1;
    padding: 0;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: unset;
  }
}



.nd_head-top_btn {
  color: #333;
  font-family: $font-acumincond;
  font-size: 1.15em;
  letter-spacing: 0.02em;
  position: relative;
  &:before {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  @include screendm(desktop) {
    &:hover {
      &:before {
        background-color: currentColor;
      }
    }
  }
  &:active,
  &[aria-expanded="true"] {
    &:before {
      background-color: currentColor;
    }
  }
}

.nicon {
  display: inline-block;
  height: auto;
  width: 1.2em;
  path, circle, line {
    fill: transparent;
    stroke: currentColor;
    stroke-width: 7px;
  }
}


.nd_head-main {
  align-items: flex-end;
  column-gap: 2em;
  display: flex;
  flex: 1;
}

.nd_head-aux {
  align-items: flex-end;
  display: flex;
  column-gap: 2em;
  flex: 0 1 auto;
  .icon {
    height: 1.3em;
  }
  @include screen(mobile) {
    align-items: center;
    column-gap: 1.5em;
  }
}

.nd_head-cart {
  .header-main-cart-qty {
    background-color: transparent;
    font-family: $font-mono;
    text-align: center;
    min-width: unset;
    height: unset;
    padding: 0;
    transform: translate(-50%,-45%);
  }
  &.nd_cart-on {
    svg path:first-child {
      fill: #82311F;
    }
  }
}


.nd_head-panels {
  background-color: transparent;
  color: #333;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  transition: all 0.2s ease-in-out;
  width: 100%;
  &.open {
    background-color: #fff;
    max-height: 70em;
  }
  @include screen(mobile) {
    display: none;
  }
}

.nd_head-panel {
  display: flex;
  column-gap: 2em;
}

.nd_head-sub {
  padding: 2em 0 4em;
  opacity: 0;
  position: absolute;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: 100%;
  z-index: -1;
  &[aria-expanded="true"] {
    opacity: 1;
    pointer-events: initial;
    position: relative;
    z-index: 2;
  }
}

.nd_head-sub_group {
  flex: 1;
  li ~ li {
    margin-top: 0.5em;
  }
}

.nd_head-title {
  @include smoothfont;
  font-family: $font-acumincond;
  font-size: 1.15em;
  letter-spacing: 0.02em;
  margin-bottom: 1.25em;
  text-transform: uppercase;
}



.nd_head-link {
  @include smoothfont;
  position: relative;
  text-decoration: none;
  &:before {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 1px;
    left: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    width: 100%;
  }
  @include screendm(desktop) {
    &:hover {
      &:before {
        background-color: currentColor;
      }
    }
  }
  &:active {
    &:before {
      background-color: currentColor;
    }
  }
}

.nd_head-promo_img {
  height: auto;
  max-width: 10em;
  width: 100%;
}

.nd_head-promo {
  .nd_head-link {
    font-family: $font-acumincond;
    font-size: 1em;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1.3;
    text-transform: uppercase;
  }
  p {
    margin-top: 1.5em;
  }
}

.nd_menumob {
  background-color: #fff;
  box-sizing: border-box;
  color: #333;
  display: none;
  flex-direction: column;
  height: 100vh;
  height: 100dvh;
  min-height: 100%;
  height: -webkit-fill-available;
  left: 0;
  opacity: 0;
  padding: 1.5em 1.5em 0;
  position: fixed;
  pointer-events: none;
  top: 0;
  transform: translate(0, 0);
  transition: opacity 0.2s ease-out;
  width: 100vw;
  z-index: -1;
  &.active {
    opacity: 1;
    pointer-events: initial;
    z-index: 900;
  }
  .nd_menumob-logo {
    path {
      fill: currentColor;
    }
  }
  .icon-cart-close {
    display: inline-block;
    height: auto;
    width: 1.05em;
    path {
      fill: currentColor;
      stroke: currentColor;
      stroke-width: 0px;
    }
  }
}

.nd_menumob-logo {
  display: inline-block;
  transform: translate(-0.4em, -0.3em);
  width: fit-content;
  .svg-logo {
    display: inline-block;
    height: 1.65em;
  }
}

.nd_menumob-content {
  background-color: #fff;
  box-sizing: border-box;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 5em 0 0;
  position: relative;
  z-index: 2;
  .nd_head-sub_group {
    font-size: 4.5vw;
  }
}

.nd_menumob-content::-webkit-scrollbar {
  background-color: #ccc;
  height: 8px;
  width: 4px;
}

.nd_menumob-content::-webkit-scrollbar-thumb {
  background: #333;
}

.nd_menumob-panel {
  max-height: 0;
  pointer-events: none;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out;
  &[aria-hidden="false"] {
    max-height: 100vh;
    opacity: 1;
    pointer-events: initial;
  }
  .nd_menumob-top_link {
    margin-bottom: 0.7em;
  }
  .nd_head-sub_group ~ .nd_head-sub_group {
    margin-top: 0.5em;
    .nd_head-title {
      margin-top: 2em;
    }
  }
}

.nd_menumob-back {
  top: 5rem;
  left: 1.5rem;
  opacity: 0;
  position: absolute;
  transition: opacity 0.2s ease-in-out;
  &.active {
    font-size: 4.2vw;
    opacity: 1;
    pointer-events: initial;
  }
}

.nd_menumob-top_link {
  display: inline-block;
  font-family: $font-acumincond;
  font-weight: 600;
  font-size: 7vw;
  letter-spacing: 0.02em;
  line-height: 1.3;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: unset;
}

button.nd_menumob-top_link {
  &[aria-expanded="true"] {
    display: none;
  }
}

.nd_menumob-bottom {
  display: flex;
  flex-direction: column;
  padding: 1em 0 10vh;
  row-gap: 1em;
  .nd_head-link {
    font-size: 3.5vw;
  }
  &.off {
    bottom: 0;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }
}

.nd_headCart {

}


.nd_head-nodesk {
  @include screendm(desktop) {
    display: none;
  }
}

.nd_head-nomob {
  @include screen(mobile) {
    display: none;
  }
}


