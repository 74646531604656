/*================ Color Variables ================*/

// Text colors
$color-body-text: #333;

// Backgrounds
$color-body: #fff;

// Helper colors for form error states
$color-disabled: #000;
$color-disabled-border: #000;
$color-error: #b73e25;
$color-error-bg: #b73e25;
$color-success: #000;
$color-success-bg: #000;




/*================ SVG ================*/
$svg-select-icon: '//cdn.shopify.com/s/files/1/0123/5065/2473/t/2/assets/ico-select.svg?v=14514294929565048432';


/*================ FONTS & COLORS ================*/

$color-beige-light: #f8f6f1;
$color-beige-lighter: #fafaf8;
$color-red: #b73e25;
$color-red-alt: #953c27;
$color-army: #676845;
$color-purple: #763D47;
$color-beiger: #d7d2cc;

$newbeige: #f4f1ea;
$newdarkbeige: #e0dcd0;
$notblack: #1c1a1f;

$font-acumin: acumin-pro, Helvetica, sans-serif;
$font-acumincond: acumin-pro-condensed, Helvetica, sans-serif;
$font-meta: acumin-pro-condensed, Helvetica, sans-serif;
$font-frank: franklin-gothic-urw, acumin-pro, Helvetica, sans-serif;
$font-frankcond: franklin-gothic-urw-cond, acumin-pro-condensed, Helvetica, sans-serif;
$font-mono: Overpass Mono,monospace;

@mixin bold_head {
  font-family: $font-acumincond;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}


/*================ MEDIA QUERY BREAKPOINTS ================*/

/* widths */
$desktop-min: 1440px;
$desktopmd-max: 1439px;
$desktopmd-min: 1101px;
$desktopsm-max: 1100px;
$desktopsm-min: 820px;
$mobile-max: 819px;

$deskmed_min: 821px;
$deskmed_max: 950px;

@mixin screen($screen) {
  @if $screen == desktop {
    @media only screen and (min-width: $desktop-min) {
      @content;
    }
  }
  @else if $screen == deskmed {
    @media only screen and (max-width: $deskmed_max) and (min-width: $deskmed_min) {
      @content;
    }
  }
  @else if $screen == desktopsm {
    @media only screen and (max-width: $desktopsm-max) and (min-width: $desktopsm-min) {
      @content;
    }
  }
  @else if $screen == mobile {
    @media only screen and (max-width: $mobile-max) {
      @content;
    }
  }
}

@mixin screendm($screen) {
  @if $screen == desktop {
    @media only screen and (min-width: $desktopsm-min) {
      @content;
    }
  }
  @else if $screen == mobile {
    @media only screen and (max-width: $mobile-max) {
      @content;
    }
  }
}

@mixin screenmax($screen) {
  @if $screen == desktopsm {
    @media only screen and (max-width: $desktopsm-max) {
      @content;
    }
  }
  @else if $screen == desktopm {
    @media only screen and (max-width: 1600px)  {
      @content;
    }
  }
  @else if $screen == mobile {
    @media only screen and (max-width: $mobile-max) {
      @content;
    }
  }
}

@mixin appearance($val) {
  -webkit-appearance: $val;
  -moz-appearance: $val;
  -ms-appearance: $val;
  -o-appearance: $val;
  appearance: $val;
}

@mixin smoothfont {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@mixin content_trans {
  transition: all 0.4s ease-in-out;
}

// NEW CONTAINER -------------------------------------------------------------------

@mixin nd_cont {
  box-sizing: border-box;
  position: relative;
  @include screendm(desktop) {
    margin: 0 auto;
    max-width: 1350px;
    width: 83%;
  }
  @include screen(mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@mixin nd_title {
  @include smoothfont;
  font-family: $font-acumincond;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}

@mixin arr_btn {
  position: relative;
  text-indent: -9999px;
  &:before {
    background-color: #000;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-3px,-50%);
    width: calc(100% - 3px);
    z-index: -1;
  }
  &:after {
    border: 5px solid #000;
    border-width: 4px 10px;
    border-color: transparent transparent transparent #000;
    content: '';
    display: block;
    height: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(10px, -50%);
    width: 0;
    z-index: -1;
  }
  &:hover,
  &:active {
    background-color: transparent;
    &:before {
      background-color: #999;
    }
    &:after {
      border-color: transparent transparent transparent #999;
    }
  }
} 

@mixin title_size {
  font-size: 1.6rem;
  @include screen(mobile) {
    font-size: 5vw;
  }
}

@mixin nd_cta {
  @include smoothfont;
  display: inline-block;
  font-family: $font-acumincond;
  font-size: 1.1em;
  letter-spacing: 0.02em;
  text-decoration: none;
  text-transform: uppercase;
  &:before,
  &:after {
    display: inline-block;
    transform: translate(0,-0.05em);
  }
  &:before {
    content: '[';
    margin-right: 0.04em;
  }
  &:after {
    content: ']';
    margin-left: 0.04em;
  }
  + .nd_cta-link {
    margin-left: 2em;
  }
  @include screendm(desktop) {
    &:hover {
      text-decoration: underline;
    }
  }
  &:active {
    text-decoration: underline;
  }
}

@mixin nd_input {
  @include smoothfont;
  background-color: transparent;
  border: 1px solid currentColor;
  border-width: 0 0 1px;
  box-shadow: none;
  color: inherit;
  font-family: $font-mono;
  font-size: 1em;
  margin-bottom: 0.75em;
  padding: 1em 0;
  &::-webkit-input-placeholder,
  &::placeholder {
    color: #aaa;
    font-family: $font-mono;
  }
  &:active,
  &:focus {
    box-shadow: none;
    border-color: currentColor;
    color: inherit;
    -webkit-text-fill-color: inherit;
  }
  @include screen(mobile) {
    font-size: 16px;
  }
}

@mixin nd_input_submit {
  @include arr_btn;
  background-color: transparent;
  margin: 0;
  position: absolute;
  transform: translate(-100%,0.6em);
  width: 5em;
  @include screen(mobile) {
    width: 9vw;
  }
}



